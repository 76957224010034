<template>
  <div>
    <p>
      We have studied about different forms of energy in previous modules and that energy can change from one form
      to another. But the sum of different kinds of energy in an isolated system always remains conserved. For
      example, Universe is an isolated system and the total energy in the universe remains conserved.
    </p>
    <h3>What is the principle of conservation of energy?</h3>
    <p>
      The principle of conservation of energy states that energy can neither be created nor be destroyed. It can only
      transform from one form to another.
    </p>

    <h3>Principle of Conservation of Energy for a Body Sliding Down a Hill</h3>
    <p>
      Consider a body of mass m freely falling under gravity from a height h above the surface of the earth as
      shown in the figure given below. We will calculate kinetic energy (K), potential energy (U) and total energy
      of the body at three different instants, when the body is at point A (at topmost point i.e., at height h), B
      (after the body has fallen through a distance x), and C (when the body has finally reached the ground) and
      see that the total energy (sum of kinetic energy and potential energy) is same at all instants which shows
      that it is conserved.
    </p>

    <p>
      <b>At point A,</b>
      <v-layout justify-center>
        <v-img src="/assets/PointA.png"
               max-height="450px"
               max-width="450px"
        />
      </v-layout>
      $$
      \begin{equation} \label{eq2}
      \begin{split}
      \text{Kinetic energy}&= K = 0 \;\;\;\;\;\; (\because \text{initial velocity u=0 at topmost point})
      \\ \text{Potential energy }&=U = mgh
      \\ \text{Total energy} &= K + U
      \\ &= 0 + mgh =mgh
      \end{split}
      \end{equation}
      $$
    </p>
    <p>
      <b>At point B,</b>
      <v-layout justify-center>
        <v-img src="/assets/PointB.png"
               max-height="450px"
               max-width="450px"
        />
      </v-layout>
      <br>From third equation of motion we know, \(v^2=u^2+2as\) .Therefore, substituting \(u=0\) and \(s=x\) in this
      equation we get,
      $$
      \begin{equation} \label{eq3}
      \begin{split}
      \text{}
      \\ \text{Velocity at point B} &= \sqrt{2gx}
      \\ \text{Kinetic energy}&= \frac{1}{2}mv^2
      =\frac{1}{2} \times m \times 2gx = mgx
      \\ \text{Potential energy }&= mg(h-x)
      \\ \text{Total energy} &= K + U
      \\ &= mgx + mg(h-x) = mgh
      \end{split}
      \end{equation}
      $$
    </p>
    <p>
      <b>At point C,</b>
      <v-layout justify-center>
        <v-img src="/assets/PointC.png"
               max-height="450px"
               max-width="450px"
        />
      </v-layout>
      <br>From equation \(v^2=u^2+2as\),
      $$
      \begin{equation} \label{eq4}
      \begin{split}
      \text{}
      \\ \text{Velocity at point C} &= \sqrt{2gh} \;\;\;\;\;\; (\because \text u=0 \; and \; s=h)
      \\ \text{Kinetic energy}&=\frac{1}{2}mv^2
      =\frac{1}{2} \times m \times (\sqrt{2gh})^2 =mgh

      \\ \text{Potential energy }&=0 \;\;\;\;\;\;\;\;\; (\because \text{h is 0})
      \\ \text{Total energy} &= K + U
      \\ &= mgx +0 = mgh
      \end{split}
      \end{equation}
      $$
    </p>
    <p>Therefore, in all the three cases the total energy is equal to \(mgh\), and thus it remains conserved.</p>

    <p>
      <b>Note </b> &mdash; In all the calculations above, we have ignored the effect of frictional force or any other
      opposing
      force. The above calculations are valid strictly in vacuum. However, the principle of
      conservation of energy always holds, because in the case when the body is not in vacuum, certain part of the
      mechanical energy of the ball gets converted to heat energy and other forms of energy but the total sum of
      all
      forms of energy always remains constant.
    </p>
    </p>
    <h3>MagicGraph | Conservation of Energy</h3>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Conservation of Energy';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Conservation of Energy',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
