// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeDash
} from '../../../common/edliy_utils-energy';
const Boxes = {
box1: function () {
          JXG.Options.board.minimizeReflow = 'none';
          JXG.Options.text.highlight =false;
          JXG.Options.text.fixed =true;
          JXG.Options.text.highlight =false;
          JXG.Options.text.cssStyle='fontFamily:Oswald',
          JXG.Options.image.highlight =false;
          JXG.Options.polygon.highlight =false;
          JXG.Options.line.highlight =false;
          JXG.Options.point.highlight =false;
          JXG.Options.curve.highlight =false;
          JXG.Options.slider.highlight =false;
          JXG.Options.point.showInfoBox =false;
          var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 15, 19, -5],
              keepaspectratio: true, axis:true, ticks:{visible:false},
              grid:true, showCopyright:false, showNavigation:false,
              pan:{enabled:false}, zoom:{enabled:false}});

          brd1.suspendUpdate();

          brd1.options.layer['image'] =14;
          makeResponsive(brd1);
          //Title and subtitle
          //var title1 = brd1.create('text', [9, 13, '<b> Conservation of Energy <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
          placeTitle(brd1, 'Conservation of Energy', ' ');
          //Sliders
          //var mass = brd1.create('slider',[[13,11],[16,11],[10,100,120]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'m', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
          //placeSlider(brd1, )
          var mass =placeSlider(brd1, 0, 12, 10, 100, 120, 5, ' m', ' kg');
          //mass.setAttribute({unitLabel:'kg'});
          var velocity = placeSlider(brd1, 0, 10, 0, 0, 10, 5, ' v', ' m/s');
          //brd1.create('slider',[[13,10],[16,10],[0,0,10]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'v', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
          var posX = placeSlider(brd1, 0, 8, 0, 0, 16, 16, ' x', ' m');
          posX.setAttribute({face:'^'});
          //brd1.create('slider',[[13,9],[16,9],[0,0,19]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'x', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

          //var txtMass = brd1.create('text', [18.5, 11, 'kg'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          //var txtVelocity = brd1.create('text', [18.2, 10, 'm/s'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          //var txtPosition = brd1.create('text', [18, 9, 'm'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

          //RollerCoaster function
          var rollerCoaster = brd1.create('functiongraph',
                                 [function(x){ if(x<= 8 ) {return 3*Math.cos(Math.PI*x*0.2) + 3}
                              else if(x>8 && x<=10){return  -(x-9)*(x-9) + 3*Math.cos(Math.PI*8*0.2) + 4}
                              else if(x>10 && x <= 14.2) {return Math.cos(Math.PI*x*0.5-2.12)*1 + 3.4}
                              else if (x>14 && x <15.7) {return Math.sin(Math.PI*x*0.5 -1.26)*2 +2 }
                              else {return 0}
                            }, -5, 25 ], {strokeColor: 'black', strokeWidth: 3, fillColor: '#C0C0C0', fillOpacity:0.5}
          );


          //Drag Me Cart
          var defY = 0.25;
          var cart = brd1.create('point', [function(){return posX.Value()}, function(){ if(posX.Value()<= 8 ) {return 3*Math.cos(Math.PI*posX.Value()*0.2) + 3 + defY}
                                                                          else if(posX.Value()>8 && posX.Value()<=10){return  -(posX.Value()-9)*(posX.Value()-9) + 3*Math.cos(Math.PI*8*0.2) + 4 + defY}
                                                                          else if(posX.Value()>10 && posX.Value() <= 14.2) {return Math.cos(Math.PI*posX.Value()*0.5-2.12)*1 + 3.4 + defY}
                                                                          else if (posX.Value()>14 && posX.Value() <15.7) {return Math.sin(Math.PI*posX.Value()*0.5 -1.26)*2 +2 + defY}
                                                                          else {return 0 + defY}
          }],{color: 'red', face:'o' , name:'', size:function(){return 7*brd1.canvasHeight/800}, fixed:false, visible:true, label:{offset:[0, 20], anchorX:'middle', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});


          //var cartImg = brd1.create('image', ['assets/cart.svg', [function(){return cart.X() -0.6}, function(){return cart.Y() +0.1}], [1,1]], {visible: true, fixed: true});

          //Energy
          var height = function(){ return (cart.Y() - defY)};
          var PE = function(){ return  mass.Value()*9.81*height()};
          var ME = function(){return 0.5*mass.Value()*velocity.Value()*velocity.Value() + mass.Value()*9.81*6};
          var KE = function(){return ME() - PE()};
          var instantVelocity = function(){return Math.sqrt(2*KE()/mass.Value())};

          //Tetxs
          var txtHeight = brd1.create('text', [10, 7, function(){return 'h = ' + height().toFixed(2)+' m'}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
          var txtME = brd1.create('text', [function(){return 1.5 + ME()/1000}, -1, function(){return 'T =' + ME().toFixed(1)+' J'}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          var txtPE = brd1.create('text', [function(){return 1.5 + PE()/1000}, -2, function(){return 'U =' + PE().toFixed(1)+ ' J'}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          var txtKE = brd1.create('text', [function(){return 1.5 + KE()/1000}, -3, function(){return 'K =' + KE().toFixed(1)+' J'}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          var txtInstantV = brd1.create('text', [15, 7, function(){return 'v = ' + instantVelocity().toFixed(2)+ ' m/s'}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

          //var txtHeight2 = brd1.create('text', [16, 8, 'm'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          //var txtME2 = brd1.create('text', [function(){return 2.8 + ME()/1000}, -1, 'J'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          //var txtPE3 = brd1.create('text', [function(){return 2.8 + PE()/1000}, -2, 'J'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          //var txtKE3 = brd1.create('text', [function(){return 2.8 + KE()/1000}, -3, 'J'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          //var txtInstantV2 = brd1.create('text', [16, 7, 'm/s'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

          var segmentME = brd1.create('segment', [[0,-1], [function(){return ME()/1000},-1]], {visible: true, fixed: true, strokeColor: 'orange', strokeWidth:function(){return 20*brd1.canvasHeight/800}});
          var segmentPE = brd1.create('segment', [[0,-2], [function(){return PE()/1000},-2]], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: function(){return 20*brd1.canvasHeight/800}});
          var segmentKE = brd1.create('segment', [[0,-3], [function(){return KE()/1000},-3]], {visible: true, fixed: true, strokeColor: 'red', strokeWidth:function(){return 20*brd1.canvasHeight/800}});

          //MOVEMENT
          function start(){
              posX.moveTo([16, 9], 10000);
              playImg.setAttribute({visible: false});
              pauseImg.setAttribute({visible: true});
          }

          function pause(){
              playImg.setAttribute({visible: true});
              pauseImg.setAttribute({visible: false});
              posX.moveTo([0, 9], 10);
          }

          //Play Button
          var playImg = brd1.create('image', ['/assets/play.svg', [16, -4], [1.5, 1.5]], {visible: true, fixed: true});
          playImg.on('up', start);
          playImg.setLabel('Play')
          playImg.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
          playImg.on('over', function () {this.label.setAttribute({visible:true});});
          playImg.on('out', function () {this.label.setAttribute({visible:false});});

          //Pause Button
          var pauseImg = brd1.create('image', ['/assets/reloading.svg', [16, -4], [1.5, 1.5]], {visible: false, fixed: true});
          pauseImg.on('up', pause);
          pauseImg.setLabel('pause')
          pauseImg.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
          pauseImg.on('over', function () {this.label.setAttribute({visible:true});});
          pauseImg.on('out', function () {this.label.setAttribute({visible:false});});

          brd1.unsuspendUpdate();
  }
              }
export default Boxes;
